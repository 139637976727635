import { FormikProps } from "formik";
import * as React from "react";
import Button, { ButtonVariant } from "../../../shared/button";
import CheckboxField from "../../../shared/checkbox-field";
import FileInput from "../../../shared/file-input";
import InputField from "../../../shared/input-field";
import SwitchField from "../../../shared/switch-field";
import TextareaField from "../../../shared/textarea-field";
import Arrow from "./arrow.svg";
import { QuestionnaireFormValues } from "./form";
import {
  CheckboxLabel,
  CheckboxLink,
  QuestionnaireForm,
  QuestionnaireRow,
  QuestionnaireRowAddress,
} from "./questionnaire.components";

const Questionnaire: React.FunctionComponent<
  FormikProps<QuestionnaireFormValues> & {
    children?: React.ReactNode;
    hasErrors: boolean;
  }
> = ({ children, isValid }) => {
  return (
    <QuestionnaireForm>
      <QuestionnaireRow>
        <InputField
          fullwidth={true}
          name="firstName"
          label="Imię"
          autoComplete="given-name"
        />
        <InputField
          fullwidth={true}
          name="lastName"
          label="Nazwisko"
          autoComplete="family-name"
        />
      </QuestionnaireRow>
      <InputField
        fullwidth={true}
        name="address"
        label="Ulica, numer budynku/mieszkania"
        autoComplete="address-line1"
      />
      <QuestionnaireRowAddress>
        <InputField
          name="city"
          label="Miejscowość"
          autoComplete="address-level2"
        />
        <InputField
          name="zipCode"
          label="Kod pocztowy"
          autoComplete="postal-code"
        />
      </QuestionnaireRowAddress>
      <QuestionnaireRow>
        <InputField
          fullwidth={true}
          name="phoneNumber"
          label="Numer telefonu"
          type="tel"
          autoComplete="tel"
        />
        <InputField
          fullwidth={true}
          name="email"
          label="Adres email"
          type="email"
          autoComplete="email"
        />
      </QuestionnaireRow>
      <InputField fullwidth={true} name="pesel" label="PESEL" />
      <SwitchField
        label="Status leczenia"
        fieldName="treatmentStatus"
        options={["Kwalifikacja", "Kontynuacja"]}
      />
      <TextareaField
        fieldName="description"
        label="Powód zgłoszenia"
        placeholder="Opisz swoje dolegliwości do terapii np: bezsenność, migrena, zespół jelita drażliwego..."
      />
      <FileInput label="Dołącz dokumentację medyczną jeżeli taką posiadasz (dokumentacja nie jest wymagana) lub wyślij pliki mailem" />
      <SwitchField
        label="Wybierz miasto"
        fieldName="visitCity"
        options={[
          "Warszawa",
          "Rzeszów",
          "Radom",
          "Katowice",
          "Łódź",
          "Bydgoszcz",
          "Poznań",
        ]}
      />
      <InputField
        fullwidth={true}
        name="diseases"
        label="Cierpisz na choroby przewlekłe? Jeśli tak, to jakie?"
      />
      <InputField
        fullwidth={true}
        name="medications"
        label="Przyjmujesz leki na stałe? Jeśli tak, to jakie?"
      />
      <CheckboxField fieldName="acceptsTerms">
        <CheckboxLabel>
          Akceptuję <span> </span>
          <CheckboxLink href="./privacy.pdf">
            Politykę prywatności
          </CheckboxLink>{" "}
          i{" "}
          <CheckboxLink
            href="./conditions.pdf"
            target="_blank"
            referrerPolicy="no-referrer"
          >
            Regulamin
          </CheckboxLink>
        </CheckboxLabel>
      </CheckboxField>
      <Button
        type="submit"
        variant={ButtonVariant.Secondary}
        disabled={!isValid}
      >
        Umów wizytę <Arrow />
      </Button>
      {children}
    </QuestionnaireForm>
  );
};

export default Questionnaire;
